import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ArmLeft from '../../../assets/grab-arm-left';
import { compose, display, left, zIndex } from 'styled-system';

import { scratch } from '../../../styles/animations';

const ScratchArmDiv = styled.div`
  ${compose(
    display,
    left,
    zIndex,
  )}
  position: absolute;
  bottom: 150px;
  transform: rotate(${({ isLeft }) => (isLeft ? '-' : '')}180deg)
    ${({ isLeft }) => (isLeft ? 'rotateY(180deg)' : '')};
  animation: ${({ isLeft, startGrab }) => startGrab && scratch(isLeft)} 10s linear infinite;
  transform-origin: 0 50%;
`;

const ScratchArm = forwardRef((props, ref) => {
  return (
    <ScratchArmDiv ref={ref} {...props}>
      <ArmLeft />
    </ScratchArmDiv>
  );
});

ScratchArm.propTypes = {
  ...display.protoTypes,
  ...left.propTypes,
  ...zIndex.propTypes,
  isLeft: PropTypes.bool,
  startGrab: PropTypes.bool,
};

ScratchArm.defaultProps = {
  left: -80,
  isLeft: false,
  startGrab: false,
};

export default ScratchArm;
